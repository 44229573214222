/* eslint-disable react/no-deprecated */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { isCheckLoginRequest, setStudentRole } from './actions';
import { reduxForm, Field } from 'redux-form/immutable';
import { Button } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import validate from './validate';
import TextField from '../../components/Inputs/TextField';
//Spinner
import Loading from '../../components/Loading';
import './style.scss';

const lineLoginAPI = 'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id='
  + process.env.REACT_APP_LINE_CHANNEL_ID
  + '&redirect_uri=' + process.env.REACT_APP_LINE_REDIRECT_URI
  + '%2Fauth&state=12345abcde&scope=openid%20profile%20email';

const appleLoginAPI = 'https://appleid.apple.com/auth/authorize?response_type=code%20id_token&client_id='
  + process.env.REACT_APP_APPLE_SERVICE_ID
  + '&redirect_uri=' + process.env.REACT_APP_APPLE_REDIRECT_URI
  + '&scope=name%20email&response_mode=form_post&state=12345';

class StudentLogin extends Component {

  state = {
    loading: false
  }

  componentDidMount = () => {
    //Set role to STUDENT
    this.props.setRole();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser) {
      const returnURN = queryString.parse(this.props.location.search) || {};
      this.props.history.push({
        pathname: returnURN.pathname || '/',
        search: ''
      });
    }

    if (nextProps.error) {
      this.setState({loading: false});
    }
  }

  render() {
    return (
      <div id="login-page-container">
        { this.props.error === 'undefined' && this.state.loading ? <Loading /> : null }
        <div className="row">
          <div className="back-icon" onClick={() => this.props.history.replace('/')}>
            <img alt="" src="images/back.svg" /> <span>戻る</span>
          </div>
          <section id="left-col-container" className="col-lg-7 col-md-12  col-sm-12 col-xs-12">
            <div id="left-col">
              <h1>学生側ログイン</h1>
              <form onSubmit={this.props.handleSubmit}>
                <div className="field-container">
                  <Field
                    component={TextField}
                    className="input-form"
                    name="email"
                    placeholder="メールアドレス"
                    type="email"
                    autocomplete="off"
                  />
                </div>
                <div className="field-container">
                  <Field
                    component={TextField}
                    className="input-form"
                    name="password"
                    placeholder="パスワード"
                    type="password"
                    showEye={true}
                  />
                </div>
                {this.props.error ?
                  (<div className="alert alert-danger">{this.props.error}</div>)
                  : null
                }
                <Button 
                  onClick={() => this.setState({loading: true})}
                  type="submit" disabled={!this.props.valid}
                  className={`btn-login ${this.props.valid && 'btn-active'}`}
                >
                    ログイン
                </Button>
              </form>
              <Link to='/forgot-password'>
                <p className="forget-password">
                  パスワードを忘れた方はこちら
                </p>
              </Link>
              <div className="divider-login">
                <hr />
                <span>OR</span>
              </div>
              <div className='login-authenticated'>
                <button
                  className='login-line'
                  onClick={() => { window.location.href = lineLoginAPI; }}
                >
                  <img src='images/line.svg' alt='LineIcon' />
                  &nbsp;&nbsp;LINEでログイン
                </button>
                <div className='vertical-line'></div>
                <button
                  className='login-apple'
                  onClick={() => { window.location.href = appleLoginAPI; }}
                >
                  <img src='images/apple.svg' alt='AppleIcon' />
                  &nbsp;&nbsp;APPLEでログイン
                </button>
              </div>
              <p className="subtext">
                <span>アカウントをお持ちでない方は </span><Link to="/signUpStudent" className="highlight">新規作成</Link>
              </p>
              {/* <div className="policy"> 
                <a href="https://careertrip.jp/shachomeshi-agreement/" rel="noopener noreferrer" target="_blank">
                  利用規約
                </a>
                、
                <a href="https://www.unitedwill.jp/privacy/" rel="noopener noreferrer" target="_blank">
                  プライバシーポリシー及び個人情報の取り扱い
                </a>
              </div> */}
            </div>
          </section>
          <section 
            id="right-col-container"
            style={{ backgroundImage: "url('images/background-login.png')" }}
            className="col-lg-5 hidden-md hidden-sm hidden-xs"
          >
            <div id="right-col" >
              <img alt="" src="images/logo-login.svg" className="logo" />
              <p className="meshi-slogan">
                就活の場を<br />「デスク」から「テーブル」へ
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

StudentLogin.propTypes = {
  error: PropTypes.any,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  popup: PropTypes.bool,
  controlUserMenu: PropTypes.func,
  reset: PropTypes.func,
  setPopup: PropTypes.func,
  role: PropTypes.string
};

const mapStateToProps = state => {
  return {
    role: state.get('login').get('role'),
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: (values) => dispatch(isCheckLoginRequest(values.toJS())),
  setRole: () => dispatch(setStudentRole())
});
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'loginForm', validate }),
  withImmutablePropsToJS
)(StudentLogin);