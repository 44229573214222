/* eslint-disable no-undef */
import { request } from 'utilities/Api';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { FETCH_CURRENT_ACCOUNT_REQUEST, 
  receiveCurrentAccount, 
  unsetCurrentAccount, 
  setLoading, 
  STUDENT_UPDATE_UNIVERSITY,
  studentUpdateUniversityFail,
  studentUpdateUniversitySuccess,
  SET_ACCOUNT } from './actions';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import _ from 'lodash';
import { setCommonPopup } from '../Popup/actions';

function* getCurrentAccount(action) {
  let { meta } = action; 
  try {
    let token = action.token;
    if (token) {
      yield put(setLoading(true));
      const data = yield call(request, 'functions/getUserInformation', {}, 'POST', { 
        headers: { 'X-Parse-Session-Token': token }
      });

      if (data) {
        const studentID = _.get(data, 'result.infoUser.objectId');
        const studentName = _.get(data, 'result.infoUser.fullname');

        if (action.message === 'STUDENT_COMPLETE_PROFILE' && studentID && studentName) {
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'Student_ID': data.result.infoUser.objectId, // student ID will store at Student_ID
            'Student_NAME': data.result.infoUser.fullname // student name will store at Student_NAME
          });

          window.onload = function() {
            if (typeof window.EBIS !== 'undefined') {
              // Call a method to log an event or trigger an EBIS action
              window.EBIS.track('pageview', { 
                page: window.location.href,
                argument: '6JtJdVYM',
                page_id: 'registerbutton_complete', // Fixed typo: "regesterbutton" -> "registerbutton"
                member_name: data.result.infoUser.objectId,
                amount: data.result.infoUser.fullname,
                other1: '',
                other2: '',
                other3: '',
                other4: '',
                other5: ''
              });
            }
          };
          window.ebis = window.ebis || [];
          ebis.push({
            argument: '6JtJdVYM',
            page_id: 'regesterbutton_complete',
            member_name: data.result.infoUser.objectId,
            amount: data.result.infoUser.fullname,
            other1: '',
            other2: '',
            other3: '',
            other4: '',
            other5: ''
          });
        }
        const role = _.get(data, 'result.infoUser.role');
        const needUpdateUniversity = _.get(data, 'result.infoUser.needUpdateUniversity');
        if (role === 'STUDENT' && needUpdateUniversity) {
          yield put(setCommonPopup('studentUpdateuniversity', 1));
        }
        firebase.analytics().setUserProperties({role: data.result.infoUser.role});
        firebase.analytics().setUserId(data.result.infoUser.objectId);
        yield put(receiveCurrentAccount(data.result.infoUser, token, action.message, meta));
        const message = yield select(state => state.get('account').get('message'));
        if (message === 'PRESIDENT_SIGNUP') {
          yield put(replace('/welcome'));
        }
      }
    } else {
      firebase.analytics().setUserProperties({role: 'GUEST'});
      firebase.analytics().setUserId('guest');
      /*yield put(setLoading(true));
      const data = yield call(request, 'functions/getUserInformation', {}, 'POST');
      yield put(receiveCurrentAccount(data.result.infoUser, null, action.message, meta));*/
    }
  } catch (e) {
    yield put(unsetCurrentAccount(meta));
  }
}

function* handleStudentUpdateUniversity(action) {
  let { meta, payload } = action; 
  try {
    yield call(request, 'functions/studentUpdateUniversity', payload, 'POST');
    yield put(studentUpdateUniversitySuccess({}, meta));
  } catch (error) {
    yield put(studentUpdateUniversityFail({ error: error.error || error.message}, meta));
  }
}

function* setCurrentAccountFUnction(action) {
  yield put(setLoading(true));
  yield put(receiveCurrentAccount(action.data, action.token));
}

export default function* watchAccount() {
  yield takeEvery(FETCH_CURRENT_ACCOUNT_REQUEST, getCurrentAccount);
  yield takeEvery(STUDENT_UPDATE_UNIVERSITY, handleStudentUpdateUniversity);
}

export function* watchSetAccount() {
  yield takeEvery(SET_ACCOUNT, setCurrentAccountFUnction);
}

 