import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { reduxForm, Field, formValueSelector } from 'redux-form/immutable';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import queryString from 'query-string';
import { getValueByKey } from '../Resources/selectors';
import Answer from '../../components/Inputs/Answer';
import { getResources } from '../Resources/actions';
import { registerAccount } from '../SignUpForPresident/actions';
import PropTypes from 'prop-types';
import TextField from '../../components/Inputs/TextField';
import { Input } from 'components/Form';
import validate from './validate';
import Checkbox from 'rc-checkbox';
import './style.scss';

const selector = formValueSelector('SignUpForPresident');


class SignUpForPresident extends Component {

  constructor(props) {
    super(props);
    this.showExtraField = this.showExtraField.bind(this);
    this.state = { extraField: false, disabled: true };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser) {
      const returnURN = queryString.parse(this.props.location.search) || {};
      

      this.props.history.push({
        pathname: returnURN.pathname || '/',
        search: ''
      });
    }
  }

  showExtraField(value) {
    this.setState({
      extraField: value
    });
    if (!value) {
      this.props.change('extraAnswer', '');
    }
  }

  handleSetDisabled = () => this.setState({ disabled: !this.state.disabled });

  render() {
    const { extraField, disabled } = this.state;

    return (
      <div id="signup-page-container">
        <div className="row">
          <div className="back-icon" onClick={this.props.history.goBack}>
            <img alt="" src="images/back.svg" /><span>戻る</span>
          </div>
          <section id="left-col-container" className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <div id="left-col">
              <h1>アカウント申請</h1>
              <form onSubmit={this.props.handleSubmit}>
                {/* Full Name */}
                <div className="field-container">
                  <Field
                    component={TextField}
                    className="input-form"
                    name="fullname"
                    placeholder="お名前"
                    type="text"
                    maxLength={15}
                    autocomplete="off"
                  />
                </div>
                {/* Company name */}
                <div className="field-container">
                  <Field
                    component={TextField}
                    className="input-form"
                    name="companyName"
                    placeholder="会社名"
                    maxLength={40}
                    type="text"
                    autocomplete="off"
                  />
                </div>
                {/* Email */}
                <div className="field-container">
                  <Field
                    component={TextField}
                    className="input-form"
                    name="email"
                    placeholder="メールアドレス"
                    type="email"
                    autocomplete="off"
                  />
                </div>
                {/* Password */}
                <div className="field-container">
                  <Field
                    component={TextField}
                    className="input-form"
                    name="password"
                    placeholder="パスワード"
                    type="password"
                    showEye={true}
                  />
                </div>
                {/* Phone number */}
                <div className="field-container">
                  <Field
                    component={TextField}
                    className="input-form"
                    name="phone"
                    placeholder="電話番号"
                    type="text"
                    autocomplete="off"
                  />
                </div>
                {/* Awnser */}
                <div className="field-container">
                  <Field
                    component={Answer}
                    className="form-control"
                    name="answer"
                    showExtraField={this.showExtraField}
                    getResources={this.props.getResources}
                    answerList={this.props.answerList}
                    answer={this.props.answer}
                  />
                </div>
                {extraField ? (
                  <div className="field-container">
                    <Field
                      name="extraAnswer"
                      type="text"
                      placeholder="紹介者名"
                      maxLength={15}
                      customForm={true}
                      component={Input}
                    />
                  </div>
                ) : null}
                {/* Description */}
                <p className="description">
                申請後3営業日以内に<br />
                ご登録のメールアドレスまたは<br />
                電話番号へご連絡いたします。
                </p>
                {this.props.error ?
                  (<div className="alert alert-danger">{this.props.error}</div>)
                  : null
                }
                {/* Checkbox privacy policy check box */}
                <div className='checkboxGroup'>
                  <Checkbox onClick={() => this.handleSetDisabled()} />
                  <span className="checkboxLabel">
                    <a href="https://careertrip.jp/shachomeshi-agreement/" target="_blank" rel="noopener noreferrer">
                      利用規約
                    </a>、
                    <a href="https://www.unitedwill.jp/privacy/" target="_blank" rel="noopener noreferrer">
                      プライバシーポリシー
                    </a>、
                    <a
                      href="https://www.unitedwill.jp/personal-information-protection8/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      個人情報の取り扱い
                    </a>
                    についての同意
                  </span>
                </div>
                <Button
                  type="submit"
                  disabled={disabled || !this.props.valid}
                  className={`btn-signup-president ${(!disabled && this.props.valid) && 'btn-active'}`}
                >
                  申請
                </Button>
              </form>
            </div>
          </section>
          <section
            id="right-col-container"
            style={{ backgroundImage: "url('images/background-login.png')" }}
            className="col-lg-5 hidden-md hidden-sm hidden-xs"
          >
            <div id="right-col" >
              <img alt="" src="images/logo-login.svg" className="logo" />
              <p className="meshi-slogan">
                就活の場を<br />「デスク」から「テーブル」へ
              </p>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

SignUpForPresident.propTypes = {
  error: PropTypes.any,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  reset: PropTypes.func,
  change: PropTypes.func,
  virtual: PropTypes.number,
  getResources: PropTypes.func,
  answerList: PropTypes.array,
  answer: PropTypes.string,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  location: PropTypes.object,
  currentUser: PropTypes.object
};

const mapStateToProps = state => {
  return {
    answerList: getValueByKey(state, 'answersforpresident'),
    answer: selector(state, 'answer'),
    currentUser: state.get('account').get('data')
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: (values) => dispatch(registerAccount(values)),
  getResources: () => dispatch(getResources(['AnswersForPresident']))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: 'SignUpForPresident', validate }),
  withImmutablePropsToJS
)(SignUpForPresident);

